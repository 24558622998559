<template>
  <v-overlay
    absolute
    opacity="0.8"
    :value="!onLine"
    z-index="0"
  >
    <RotatingLogo />
  </v-overlay>
</template>
<script>
import RotatingLogo from '@/components/RotatingLogo.vue';

export default {
  components: {
    RotatingLogo,
  },
  data: () => ({
      onLine: navigator.onLine,
  }),
  mounted() {
    window.addEventListener('online', this.handleNetworkChange);
    window.addEventListener('offline', this.handleNetworkChange);
  },
  beforeDestroy() {
    window.removeEventListener('online', this.handleNetworkChange);
    window.removeEventListener('offline', this.handleNetworkChange);
  },
  methods: {
    handleNetworkChange(event) {
      const { type } = event;
      this.onLine = type === 'online';
    },
  },
  watch: {
    onLine(v) {
      if (v) {
        this.$notify.success(this.$t('connectivity.online'));
        return;
      }
      this.$notify.error(this.$t('connectivity.offline'), { hide: false });
    },
  },
};
</script>
